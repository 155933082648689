@import "../mainColors";

.editor {
  width: 100%;
  min-height: 500px;

  .se-toolbar-sticky {
    top: 70px !important;
  }
}

.error-editor {
  border: 1px solid red;
}
