@import "../mainColors";

.content-header {
  color: black;

  .input-group {
    margin-right: 12px;

    input {
      height: 32px;
    }

    .ant-select {
      min-width: 190px !important;
      margin-right: 12px;
    }
  }
  .send-notification-btn {
    background-color:  #06191d;
    margin-right: 0 !important;
  }

  .down-part {
    width: calc(100% - 300px);
    margin-left: auto;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .header-btn {
      margin-top: 4px;
      background-color:$brandColor;
      &.filter-btn {
        margin-left: 12px;
        width: 120px;
        height: 34px;
        &.open-filters-btn {
          background-color: #F3F5F6;
          color: #06191d;
          i {
            margin-right: 8px;
          }
        }
      }
    }


    .down-part-left {
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;

      .search_box {
        flex-grow: 1;
        display: flex;
        flex-wrap: nowrap;
        margin-top: 4px;

        .search_btn {
          float: left;
          border: 1px solid $borderColor;
          border-right: none;
          border-radius: .25rem 0 0 .25rem;
          background-color: transparent;
          padding: 4px 0 4px 12px;
          display: flex;
          align-items: center;

          i {
            color: grey;
          }
          svg{
            path{
              fill: $borderColor;
            }
          }
        }

        .input-group {
          flex-grow: 1;
          margin-bottom: 0;

          input {
            border-left: none !important;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            &:focus {
              border: 1px solid $borderColor;
            }
          }
        }
      }
    }

    .right-bottom-part {
      font-size: 16px;
      font-weight: 600;
      margin-top: 4px;
    }
  }

  .filters-wrapper {
    transition: height .2s ease-in-out;
    height: 0;

    &.open {
      transition: height .2s ease-in-out;
      height: 55px;
    }
  }





  @media screen and (max-width: 1200px) {
    .down-part {
      .down-part-left {
        .search_box {
          width: 250px;
        }
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .down-part {
      .down-part-left {
        .search_box {
          width: 180px;
        }
      }
    }
  }
}
