@import "../mainColors";

.admin-edit-file {
  .main-content {
    .image-adder {
      margin-bottom: 20px;

      .fileUploader {
        &.error {
          .fileContainer {
            border-color: red;
          }
        }
      }



      .fileContainer .uploadPicturesWrapper {
        max-height: 600px;
        overflow-y: auto;
      }

      .flex-wrapper-left {
        margin-top: 15px;
      }
    }
  }
}
