@import "../mainColors";

.moderators-wrapper-table {
  overflow-y: hidden;
  overflow-x: auto;
  min-width: 600px;
  background: white;
  width: 100%;
  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f4f4f4;
    border: 1px solid $borderColor;
    border-bottom: none;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    padding: 10px;

    .table-head {
      text-transform: uppercase;
      font-weight: 500;
      color: #000;
    }
  }

  .no-moderators-data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }

  .table-head {
    &.username {
      width: calc(33% - 100px);
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.email {
      width: calc(33% - 100px);
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.blocked {
      width: calc(33% - 100px);
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
      .status{
        display: inline-block;
        color: #E6A01E;
        border: 1px solid #E6A01E;
        padding: 1px 10px;
        border-radius: 3px;
        font-size: 13px;
        width: 80px;
        text-align: center;
      }
      .active-status{
        color: #14BFF4;
        border: 1px solid #14BFF4;
      }
    }

    &.actions {
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .table-body {
    padding: 10px;
    border: 1px solid $borderColor;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;

    .table-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $borderColor;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .actions {
        .btn {
          background: white;
          border-radius: 4px;
          padding: 5px 20px;
        }

        .editButton {
          border: 1px solid #73d072;
          color: #73d072;
          margin-right: 15px;
        }

        .deleteButton {
          border: 1px solid #d05357;
          color: #d05357;
        }
      }
    }
  }
}
