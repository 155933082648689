@import "../mainColors";
.skelton-item {
  width: 100%;
  display: flex;
  padding:25px 0;
  flex-direction: column;
  .ant-skeleton:last-child{
    .ant-skeleton-title{
      display: none;
    }
  }
  .ant-skeleton-paragraph{
    margin-top: 0!important;
    li{
      border-radius: 4px;
      height: 42px;
    }
  }
}
.skelton-small-item {
  width: 200px;
  display: flex;
  padding: 5px 0;
  flex-direction: column;
  .ant-skeleton:last-child{
    .ant-skeleton-title{
      display: none;
    }
  }
  .ant-skeleton-paragraph{
    margin: 0!important;
    li{
      border-radius: 4px;
      height: 15px;
    }
  }
}

