@import "../mainColors";

.moderators {
  position: relative;
  .role-selecting-box {
    margin: 25px 0;
    display: flex;
    justify-content: space-between;

    label {
      margin-bottom: 20px;
      display: inline-block;
    }
  }

  .access-pages-wrapper {
    h3 {
      margin-bottom: 24px;
    }

    .ant-col {
      margin-bottom: 16px;
    }
  }
.password-wrapper{
  .visibility-icon{
    position: absolute;
    bottom: -3px;
  }
}
}
