@import "../mainColors";

.link-button {
  font-size: 15px;
  color: #fff;
  border-radius: 5px;
  padding: 6px 16px;
  border: none;
  text-transform: capitalize;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background: $brandColor;
  //background: linear-gradient(to right,  $brandSecColor, $brandColor);

  &.media-select-btn {
    height: 34px;
    //margin-top: 21px;
    margin-bottom: 15px;
    margin-right: 50px;
  }

  &.media-select-error {
    background: linear-gradient(to right,  #FF4E41, #FF4E41);
  }

  &.bg-red {
    background: linear-gradient(to right,  #FF4E41, #FF4E41);
  }

  &.bg-grey {
    background: linear-gradient(to right,  #AEB5BB, #AEB5BB);
  }

  &.bg-green {
    background: #0ACC84;
  }

  &.bg-white {
    background: linear-gradient(to right,  white, white);
    border: 1px solid #ccc;
    color: black;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.bg-default-important {
    background:  $brandColor!important;
    color: white;
  }

  &:disabled {
    color: #fff;
    background: linear-gradient(to right, #AEB5BB, #AEB5BB);
    opacity: .65;
    cursor: default;
  }

  &:hover {
    opacity: .85;
  }

  .flex-row {
    display: flex;
    align-items: center;

    .ant-spin-dot {
      height: 18px;
    }

    &.loadingWithTitle {
      .ant-spin {
        margin-left: 15px;
      }
    }
  }

  .title {
    display: flex;
    justify-content: center;

    i {
      font-size: 18px;
      padding: 3px 0;
    }
  }
}
