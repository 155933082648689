@import "../mainColors";

.right-menu {
  display: flex;
  align-items: center;
  cursor: pointer;

  .dropdown-component {
    .custom-dropdown-inner {
      .notification-icon {
        transform: rotate(45deg);
      }
    }

    .clear-notifications-btn {
      display: flex;
      align-items: center;
      padding: 2px 12px;
      border-radius: 4px;
      color: #8993a4;
      cursor: pointer;
      transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;

      i {
        margin-right: 10px;
      }

      &:hover {
        background-color: #F4F5F7;
      }
    }

    .empty-view-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 60px 0 80px;

      img {
        width: 120px;
      }

      .empty-view-content {
        max-width: 300px;
        text-align: center;
        margin-top: 36px;

        h2 {
          color: #172b4d;
          font-size: 1.42857143em;
          font-style: inherit;
          font-weight: 500;
          letter-spacing: -.008em;
          line-height: 1.2;
          margin: 24px 0 16px;
        }

        p {
          font-size: 1em;
          line-height: 24px;
          margin: 16px 0;
          color: #172b4d;
        }
      }
    }
  }

  .right-menu-item {
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 10px;
    transition: background 0.1s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
    -webkit-transition-duration: 0.1s, 0.15s;
    transition-duration: 0.1s, 0.15s;

    i {
      color:  #06191d;
    }

    &.active {
      background-color: rgba(222, 235, 255, 0.6);

      i {
        color: $brandColor;
      }
    }

    &:hover {
      background-color: rgba(222, 235, 255, 0.9);
    }

    &:hover i {
      color: $brandColor;
    }
  }
}

@media screen and (max-width: 800px) {
  .right-menu {
    max-width: 250px;

    .dropdown-inner {
      padding: 8px;

      .username {
        font-size: 13px;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .right-menu {
    .dropdown-inner {
      .username {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .right-menu {
    width: 65px;

    .dropdown-inner {
      i {
        font-size: 15px !important;
      }
    }
  }
}