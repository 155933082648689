@import "../mainColors";

.language-tabs, .custom-tabs {
  width: 100%;
  //margin-bottom: 20px;

  .ant-tabs-nav {
    .ant-tabs-tab {
      transition: color 0.2s linear;
      border: 1px solid $borderColor !important;
      background-color: #F5F6F7 !important;
      margin-right: 4px !important;
      border-radius: 6px 6px 0 0;
      font-weight: 500;
      position: relative;

      .have-badge {
        display: inline-block;
        padding-right: 20px;
      }

      .badge {
        position: absolute;
        z-index: 1;
        top: 9px;
        right: 9px;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $brandColor;
        background: white;
        color: $brandColor;
        border-radius: 50%;
        line-height: 10px;
        font-size: 13px;
        font-family: Regular;
      }
    }

    .ant-tabs-tab:hover {
      color: rgba(0, 0, 0, 0.65);
    }

    .ant-tabs-tab-active {
      border-bottom-color: $brandColor !important;
      color: rgba(0, 0, 0, 0.9) !important;
      background-color: #ffffff !important;
      font-weight: 500;
    }
  }

  .ant-tabs-bar {
    border-bottom: 1px solid $borderColor;
  }

  .ant-tabs-ink-bar {
    background-color: $brandColor;
  }
}
