@import "../../mainColors";

.color-piker {
  margin-bottom: 16px;

  label {
    display: block;
    font-weight: 500;
    color: #000000;
    margin-bottom: 16px;
  }

  .color-wrapper {

    .display-color {
      padding: 5px;
      background: #fff;
      border: 1px solid $borderColor;
      display: inline-block;
      cursor: pointer;
      border-radius: 4px;

      .color-inner {
        width: 100px;
        height: 25px;
        border-radius: 3px;
      }
    }

    .popover-wrapper {
      position: absolute;
      z-index: 2;

      .cover {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
      }
    }
  }
}
