@import '../mainColors';
@import '../mixins';

.add-folder-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: black;
  font-weight: 600;
  padding: 6px 16px;
  cursor: pointer;
  img {
    margin-right: 8px;
  }
}
.add-folder-card {
  &:before {
    top: -10px;
    left: 105px;
    @media all and (max-width: 550px){
      right: 10px;
    }
  }

  &:after {
    top: -12px;
    left: 103px;
    @media all and (max-width: 550px){
      right: 8px;
    }
  }
  @media all and (max-width: 550px){
    left: 10px;
  }
}
.add-folder-card{
  width: 400px;
  background-color: white;
  -webkit-box-shadow: #ddd 0px 0px 3px 1px;
  -moz-box-shadow: #eee 0px 0px 3px 1px;
  box-shadow: #e5e5e5 0px 0px 3px 1px;
  border-radius: 4px;
  &:before {
    content: "";
    border-bottom: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    z-index: 10;
  }

  &:after {
    content: "";
    border-bottom: 12px solid #f1f1f1;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    position: absolute;
    z-index: 9;
  }
  .form-wrapper {
    padding: 30px 30px 0;
  }
  .input-group {
    margin-bottom: 6px;
    input {
      padding-left: 40px;
    }
    position: relative;
    .placeholder-img {
      position: absolute;
      width: 24px;
      top: 8px;
      left: 10px;
      opacity: .5;
    }
  }

  .folder-name-broken {
    height: 25px;
    margin-bottom: 6px;
    span {
      color: red;
    }
  }

  .ant-tabs-tab {
    margin: 0;
  }
  .buttons-wrapper {
    border-top:1px solid $borderColor;
    padding: 15px 30px;
    display: flex;
    justify-content: flex-end;
    .link-button {
      padding: 6px 16px;
      transition: .3s;
      margin-left: 8px;
      &:hover {
        -webkit-box-shadow: 0px 0px 6px 0px rgba(180, 180, 180, 1);
        -moz-box-shadow: 0px 0px 6px 0px rgba(180, 180, 180, 1);
        box-shadow: 0px 0px 6px 0px rgba(180, 180, 180, 1);
      }
    }
  }
}
.add-folder-dropdown{
  z-index: 99;
  &.in-selector-modal{
    z-index: 1001;
  }
}
