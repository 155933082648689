@import "../mainColors";


.implementation {
  position: fixed;
  background-color: white;
  bottom: 0;
  left: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
  transition: all .2s linear;
  will-change: width, opacity;
  box-shadow: 0px 1px 4px 0px rgba(9, 30, 66, 0.1);

  .inner {
    display: flex;
    justify-content: space-between;
    padding: 7px 14px;
    border-top: 1px solid $borderColor;
    flex-wrap: nowrap;

    span {
      color: $textColor;
    }

    img, svg {
      height: 14px;
    }
  }

  &.show {
    opacity: 1;
    width: 249px;
    @media screen and (max-width: 1200px) {
      width: 219px;
    }
  }
}
