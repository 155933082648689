@import "../mainColors";
@import "../mixins";


.media-action-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  //margin-top: -20px;
  padding: 12px 12px 12px 14px;
  width: calc(100% + 60px);
  border-bottom: 1px solid $borderColor;
  //box-shadow: 0px 1px 4px 0px rgba(9, 30, 66, 0.1);
  position: sticky;
  top: 50px;
  background-color: white;
  z-index: 100;

  .material-icons {
    color: #3F3F41;
    cursor: pointer;
    margin-right: 4px;
  }

  .add-buttons-wrapper {
    display: flex;
    flex-wrap: wrap;

    .link-button {
      background: transparent;
      color: black;
      font-weight: 600;

      .title {
        img {
          margin-right: 8px;
        }
      }
    }

    .file-uploader {
      margin-top: 4px;

      & + .link-button {
        margin-top: 4px;
      }
    }
  }

  .right-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;

    .search-icon {
      margin-right: 8px;

      &.is-closed {
        display: none;
      }
    }

    .delete-btn {
      background-color: transparent;
      padding-right: 20px;
      border-right: 1px solid $borderColor;

      .delete-icon {
      }
    }

    .toggle-track-view {
      display: flex;
      margin-left: 24px;

      .list-view-button {
        margin: 0;
        background: none;
        border: 1px solid transparent;
        padding: 6px 10px;

        &.selected {
          border: 1px solid $borderColor;
        }
      }
    }
  }

  @keyframes specialFadeInRight {
    from {
      opacity: 0;
      transform: scale(0.9);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@media all and (max-width: 1200px) {
  .media-action-buttons {
    margin-left: -15px;
    margin-top: -15px;
    padding: 6px;
    width: calc(100% + 30px);
    top: 50px;
  }
}

@media all and (max-width: 800px) {
  .media-action-buttons {
    margin-left: -8px;
    margin-top: -15px;
    padding: 6px;
    width: calc(100% + 16px);
  }
}
