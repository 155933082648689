.slider-add-edit {
  position: relative;

  .resources {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .input-group {
      width: 48%;
    }
  }

  .row {
    display: flex;

    .main-media {
      .media-card {
        min-width: 200px;
      }
    }
  }
}
