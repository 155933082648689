@import "../../mainColors";

.breadcrumb-wrapper {
  margin-bottom: 10px;

  &.absolute-wrapper {
    position: absolute;
    width: 300px;
  }
  .breadcrumb-item {
    font-size: 24px;
    font-weight: 500;
    color:  #06191d;
    letter-spacing: normal;
    cursor: default;
    .link-item {
      cursor: pointer;
      transition: all .2s linear;
      &:hover {
        color: $brandColor;

    }}
  }
}