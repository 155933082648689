@import "../mainColors";
@import "../fonts";

.admin-layout {
  position: relative;
  display: flex;
}

.loading {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.admin-inner-component {
  width: calc(100% - 20px);
  -webkit-transition: width .2s linear;
  transition: width .2s linear;
  float: right;
  background: #f2f7fb;
  min-height: calc(100vh - 56px);
  margin-top: 56px;
  position: relative;


  .page-title {
    font-size: 24px;
    font-weight: 500;
    color:  #06191d;
    letter-spacing: normal;
    transition: all .2s linear;
  }

  &.size-sm {
    width: calc(100% - 250px);

    .page-title {
      left: 266px;
    }
  }

  .content-wrapper {
    background-color: #fff;
    border-left: 1px solid $borderColor;
    box-shadow: 0px 1px 4px 0px rgba(9, 30, 66, 0.1);
    height: 100%;
    min-height: calc(100vh - 85px);

    .main-content {
      min-height: calc(100vh - 90px);
      padding: 20px 30px 0px;
    }

    .general-add-edit {
      position: relative;
    }

    .editor-wrapper {
      margin-bottom: 20px;
    }

    .empty-view {
      width: 100%;
      min-height: calc(100vh - 450px);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 150px;
        height: 150px;
      }

    }
  }
}

.flex-wrapper-right {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}


@keyframes rotateIcon {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(180deg);
  }
}

@media screen and (max-width: 1200px) {
  .admin-inner-component {

    .content-wrapper {
      .main-content {
        padding: 15px 15px 40px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .admin-inner-component {
    margin-top: 50px;
    min-height: calc(100vh - 50px);
    //padding: 10px;

    &.size-sm {
      width: calc(100% - 220px);
    }
  }
}
