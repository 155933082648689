@import "../../mainColors";

.dropdown-component {
  margin-left: 10px;
  position: relative;

  .custom-dropdown-inner {
    img {
      width: 16px;
      height: 16px;
    }

    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: background 0.1s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
    transition-duration: 0.1s, 0.15s;

    .inner-img {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      //background-color:  #06191d;
      border-radius: 5px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    i {
      color:  #06191d;
    }


    &.active {
      //background-color: rgba(222, 235, 255, 0.6);

      i {
        color: $brandColor;
      }
    }

    &:hover {
      //background-color: rgba(222, 235, 255, 0.9);
    }

    &:hover i {
      color: $brandColor;
    }


  }

  .custom-dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 40px;
    background-color: white;
    padding: 10px 20px;
    z-index: 101;
    border-radius: 4px;
    min-width: 400px;
    cursor: default;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;

    .custom-dropdown-title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid $borderColor;
      .custom-dropdown-title {
        margin: 12px 0;
        color: $textColor;
        font-size: 20px;
      }
    }

    &.showDropdown {
      display: flex;
      flex-direction: column;
    }
  }

  &.menu-wrapper {

    .custom-dropdown-content {
      min-width: 150px;
      padding: 4px 0;
    }

    .menu-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: $textColor;
      padding: 6px 20px;
      transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;

      &:first-child {
        border-radius: 4px 4px 0 0;
      }

      &:last-child {
        border-radius: 0 0 4px 4px;
      }
      &:hover {
        background-color: #F4F5F7;
      }
    }
  }

  &:last-child {

    .custom-dropdown-inner {
      padding: 6px;
    }
  }
}