@import '../mainColors';

.list-item-wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  color: #696B76;
  font-weight: 500;
  padding: 6px 16px;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
  position: relative;

  .selecting-checkbox {
    position: relative;
    left: -7px;
    display: flex;
    align-items: center;

    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &.selected {
    //border-color: $brandColor;
    background-color: #f2f7fb;
  }

  .general-info-content {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .general-info-item {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      line-height: 30px;
      height: 100%;

      img {
        width: 45px;
        height: 45px;
        object-fit: cover;
      }

      .color {
        border: 1px solid $borderColor;
        margin: 0 auto;
        display: inline-block;
        width: 80px;
        border-radius: 4px;
        height: 100%;
      }

      &:first-child {
        text-align: left;
      }
    }
    .hovered {
      &:hover {
        color: $brandSecColor
      }
    }
  }

  .item-actions-part {
    display: flex;
    align-items: center;

    .status-badge {
      width: 120px;
      text-align: center;
      color: white;
      border-radius: 4px;
      line-height: 28px;
      padding: 0 10px;
      border: 1px solid $brandColor;
    }

    .status {
      line-height: 30px;
      text-align: center;
      width: 120px;
    }

    .actions {
      text-align: right;
      width: 150px;

      .link-button {
        padding: 3px 6px;
        margin-left: 8px;
      }

      .edit-button {
        background: $brandColor;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .item-actions-part {
      .status {
        width: 80px;
      }

      .actions {
        width: 120px;
      }
    }
  }
}
