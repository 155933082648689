@import "../mainColors";

.header-logo {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  z-index: 1020;
  transition: all .2s linear;

  .brand-name {
    display: flex;
    align-items: center;
    margin-left: 12px;
    font-size: 22px;
    font-weight: bold;
    color: $darkBlueColor;
  }

  svg {
    //height: 24px;
    width: 150px;

    path {
      //fill: $darkBlueColor;
    }
  }
}
