@import '../mainColors';
@import '../mixins';

.preview-modal {
  padding: 20px;
  width: 90vw;
  height: 90vh;
  position: relative;

  .header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin: 0;
    }
  }

  .content-wrapper {
    width: 100%;
    height: calc(100% - 50px);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .preview-img {
      max-height: 100%;
      max-width: 100%;
    }

    iframe {
      width: 100%;
      height: 100%;

      img {
        height: 100%;
      }

    }
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .link-button {
    padding: 6px 16px;
  }

}
